var exports = {};
exports = {
  A: {
    A: {
      K: 0,
      E: 0,
      F: 0,
      G: 0.033801,
      A: 0,
      B: 0.371811,
      kC: 0
    },
    B: "ms",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "kC", "K", "E", "F", "G", "A", "B", "", "", ""],
    E: "IE",
    F: {
      kC: 962323200,
      K: 998870400,
      E: 1161129600,
      F: 1237420800,
      G: 1300060800,
      A: 1346716800,
      B: 1381968000
    }
  },
  B: {
    A: {
      "6": 0.003558,
      "7": 0,
      "8": 0.003558,
      "9": 0.003558,
      C: 0,
      L: 0,
      M: 0,
      H: 0,
      N: 0,
      O: 0.003558,
      P: 0.117414,
      Q: 0,
      I: 0,
      R: 0,
      S: 0,
      T: 0,
      U: 0,
      V: 0,
      W: 0,
      X: 0,
      Y: 0,
      Z: 0,
      a: 0,
      b: 0.010674,
      c: 0,
      d: 0,
      e: 0,
      f: 0,
      g: 0,
      h: 0,
      i: 0,
      j: 0,
      k: 0,
      l: 0,
      m: 0,
      n: 0,
      o: 0,
      p: 0,
      q: 0,
      r: 0.003558,
      s: 0.049812,
      t: 0,
      u: 0.003558,
      v: 0.007116,
      w: 0.007116,
      x: 0.010674,
      AB: 0.003558,
      BB: 0.021348,
      CB: 0.010674,
      DB: 0.014232,
      EB: 0.007116,
      FB: 0.010674,
      GB: 0.010674,
      HB: 0.021348,
      IB: 0.021348,
      JB: 0.01779,
      KB: 0.021348,
      LB: 0.067602,
      MB: 2.88198,
      D: 1.62245
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "C", "L", "M", "H", "N", "O", "P", "Q", "I", "R", "S", "T", "U", "V", "W", "X", "Y", "Z", "a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "6", "7", "8", "9", "AB", "BB", "CB", "DB", "EB", "FB", "GB", "HB", "IB", "JB", "KB", "LB", "MB", "D", "", "", ""],
    E: "Edge",
    F: {
      "6": 1689897600,
      "7": 1692576000,
      "8": 1694649600,
      "9": 1697155200,
      C: 1438128000,
      L: 1447286400,
      M: 1470096000,
      H: 1491868800,
      N: 1508198400,
      O: 1525046400,
      P: 1542067200,
      Q: 1579046400,
      I: 1581033600,
      R: 1586736000,
      S: 1590019200,
      T: 1594857600,
      U: 1598486400,
      V: 1602201600,
      W: 1605830400,
      X: 1611360000,
      Y: 1614816000,
      Z: 1618358400,
      a: 1622073600,
      b: 1626912000,
      c: 1630627200,
      d: 1632441600,
      e: 1634774400,
      f: 1637539200,
      g: 1641427200,
      h: 1643932800,
      i: 1646265600,
      j: 1649635200,
      k: 1651190400,
      l: 1653955200,
      m: 1655942400,
      n: 1659657600,
      o: 1661990400,
      p: 1664755200,
      q: 1666915200,
      r: 1670198400,
      s: 1673481600,
      t: 1675900800,
      u: 1678665600,
      v: 1680825600,
      w: 1683158400,
      x: 1685664000,
      AB: 1698969600,
      BB: 1701993600,
      CB: 1706227200,
      DB: 1708732800,
      EB: 1711152000,
      FB: 1713398400,
      GB: 1715990400,
      HB: 1718841600,
      IB: 1721865600,
      JB: 1724371200,
      KB: 1726704000,
      LB: 1729123200,
      MB: 1731542400,
      D: 1737417600
    },
    D: {
      C: "ms",
      L: "ms",
      M: "ms",
      H: "ms",
      N: "ms",
      O: "ms",
      P: "ms"
    }
  },
  C: {
    A: {
      "0": 0,
      "1": 0,
      "2": 0,
      "3": 0,
      "4": 0,
      "5": 0,
      "6": 0.234828,
      "7": 0,
      "8": 0,
      "9": 0.085392,
      lC: 0,
      IC: 0,
      J: 0,
      NB: 0,
      K: 0,
      E: 0,
      F: 0,
      G: 0,
      A: 0,
      B: 0.021348,
      C: 0,
      L: 0,
      M: 0,
      H: 0,
      N: 0,
      O: 0,
      P: 0,
      OB: 0,
      y: 0,
      z: 0,
      PB: 0,
      QB: 0,
      RB: 0,
      SB: 0,
      TB: 0,
      UB: 0,
      VB: 0,
      WB: 0,
      XB: 0,
      YB: 0,
      ZB: 0,
      aB: 0,
      bB: 0,
      cB: 0,
      dB: 0,
      eB: 0.007116,
      fB: 0.003558,
      gB: 0,
      hB: 0,
      iB: 0.003558,
      jB: 0,
      kB: 0,
      lB: 0,
      mB: 0,
      nB: 0.028464,
      oB: 0,
      pB: 0,
      qB: 0.003558,
      rB: 0.014232,
      sB: 0,
      tB: 0,
      JC: 0.003558,
      uB: 0,
      KC: 0,
      vB: 0,
      wB: 0,
      xB: 0,
      yB: 0,
      zB: 0,
      "0B": 0,
      "1B": 0,
      "2B": 0,
      "3B": 0,
      "4B": 0,
      "5B": 0,
      "6B": 0,
      "7B": 0,
      "8B": 0,
      "9B": 0,
      AC: 0,
      BC: 0.010674,
      Q: 0,
      I: 0,
      R: 0,
      LC: 0,
      S: 0,
      T: 0,
      U: 0,
      V: 0,
      W: 0,
      X: 0.003558,
      Y: 0,
      Z: 0,
      a: 0,
      b: 0,
      c: 0,
      d: 0,
      e: 0,
      f: 0,
      g: 0,
      h: 0,
      i: 0,
      j: 0,
      k: 0,
      l: 0,
      m: 0.007116,
      n: 0,
      o: 0,
      p: 0,
      q: 0,
      r: 0,
      s: 0.003558,
      t: 0,
      u: 0,
      v: 0,
      w: 0.007116,
      x: 0,
      AB: 0,
      BB: 0.003558,
      CB: 0.003558,
      DB: 0,
      EB: 0,
      FB: 0,
      GB: 0.01779,
      HB: 0,
      IB: 0.007116,
      JB: 0.078276,
      KB: 0.003558,
      LB: 0.007116,
      MB: 0.010674,
      D: 0.021348,
      MC: 0.49812,
      NC: 1.02826,
      OC: 0.007116,
      PC: 0,
      mC: 0,
      nC: 0,
      oC: 0,
      pC: 0
    },
    B: "moz",
    C: ["lC", "IC", "oC", "pC", "J", "NB", "K", "E", "F", "G", "A", "B", "C", "L", "M", "H", "N", "O", "P", "OB", "y", "z", "0", "1", "2", "3", "4", "5", "PB", "QB", "RB", "SB", "TB", "UB", "VB", "WB", "XB", "YB", "ZB", "aB", "bB", "cB", "dB", "eB", "fB", "gB", "hB", "iB", "jB", "kB", "lB", "mB", "nB", "oB", "pB", "qB", "rB", "sB", "tB", "JC", "uB", "KC", "vB", "wB", "xB", "yB", "zB", "0B", "1B", "2B", "3B", "4B", "5B", "6B", "7B", "8B", "9B", "AC", "BC", "Q", "I", "R", "LC", "S", "T", "U", "V", "W", "X", "Y", "Z", "a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "6", "7", "8", "9", "AB", "BB", "CB", "DB", "EB", "FB", "GB", "HB", "IB", "JB", "KB", "LB", "MB", "D", "MC", "NC", "OC", "PC", "mC", "nC"],
    E: "Firefox",
    F: {
      "0": 1368489600,
      "1": 1372118400,
      "2": 1375747200,
      "3": 1379376000,
      "4": 1386633600,
      "5": 1391472000,
      "6": 1688428800,
      "7": 1690848000,
      "8": 1693267200,
      "9": 1695686400,
      lC: 1161648000,
      IC: 1213660800,
      oC: 1246320000,
      pC: 1264032000,
      J: 1300752000,
      NB: 1308614400,
      K: 1313452800,
      E: 1317081600,
      F: 1317081600,
      G: 1320710400,
      A: 1324339200,
      B: 1327968000,
      C: 1331596800,
      L: 1335225600,
      M: 1338854400,
      H: 1342483200,
      N: 1346112000,
      O: 1349740800,
      P: 1353628800,
      OB: 1357603200,
      y: 1361232000,
      z: 1364860800,
      PB: 1395100800,
      QB: 1398729600,
      RB: 1402358400,
      SB: 1405987200,
      TB: 1409616000,
      UB: 1413244800,
      VB: 1417392000,
      WB: 1421107200,
      XB: 1424736000,
      YB: 1428278400,
      ZB: 1431475200,
      aB: 1435881600,
      bB: 1439251200,
      cB: 1442880000,
      dB: 1446508800,
      eB: 1450137600,
      fB: 1453852800,
      gB: 1457395200,
      hB: 1461628800,
      iB: 1465257600,
      jB: 1470096000,
      kB: 1474329600,
      lB: 1479168000,
      mB: 1485216000,
      nB: 1488844800,
      oB: 1492560000,
      pB: 1497312000,
      qB: 1502150400,
      rB: 1506556800,
      sB: 1510617600,
      tB: 1516665600,
      JC: 1520985600,
      uB: 1525824000,
      KC: 1529971200,
      vB: 1536105600,
      wB: 1540252800,
      xB: 1544486400,
      yB: 1548720000,
      zB: 1552953600,
      "0B": 1558396800,
      "1B": 1562630400,
      "2B": 1567468800,
      "3B": 1571788800,
      "4B": 1575331200,
      "5B": 1578355200,
      "6B": 1581379200,
      "7B": 1583798400,
      "8B": 1586304000,
      "9B": 1588636800,
      AC: 1591056000,
      BC: 1593475200,
      Q: 1595894400,
      I: 1598313600,
      R: 1600732800,
      LC: 1603152000,
      S: 1605571200,
      T: 1607990400,
      U: 1611619200,
      V: 1614038400,
      W: 1616457600,
      X: 1618790400,
      Y: 1622505600,
      Z: 1626134400,
      a: 1628553600,
      b: 1630972800,
      c: 1633392000,
      d: 1635811200,
      e: 1638835200,
      f: 1641859200,
      g: 1644364800,
      h: 1646697600,
      i: 1649116800,
      j: 1651536000,
      k: 1653955200,
      l: 1656374400,
      m: 1658793600,
      n: 1661212800,
      o: 1663632000,
      p: 1666051200,
      q: 1668470400,
      r: 1670889600,
      s: 1673913600,
      t: 1676332800,
      u: 1678752000,
      v: 1681171200,
      w: 1683590400,
      x: 1686009600,
      AB: 1698105600,
      BB: 1700524800,
      CB: 1702944000,
      DB: 1705968000,
      EB: 1708387200,
      FB: 1710806400,
      GB: 1713225600,
      HB: 1715644800,
      IB: 1718064000,
      JB: 1720483200,
      KB: 1722902400,
      LB: 1725321600,
      MB: 1727740800,
      D: 1730160000,
      MC: 1732579200,
      NC: 1736208000,
      OC: 1738627200,
      PC: null,
      mC: null,
      nC: null
    }
  },
  D: {
    A: {
      "0": 0,
      "1": 0,
      "2": 0,
      "3": 0,
      "4": 0,
      "5": 0,
      "6": 0.024906,
      "7": 0.110298,
      "8": 0.067602,
      "9": 0.056928,
      J: 0,
      NB: 0,
      K: 0,
      E: 0,
      F: 0,
      G: 0,
      A: 0,
      B: 0,
      C: 0,
      L: 0,
      M: 0,
      H: 0,
      N: 0,
      O: 0,
      P: 0,
      OB: 0,
      y: 0,
      z: 0,
      PB: 0,
      QB: 0,
      RB: 0,
      SB: 0,
      TB: 0,
      UB: 0,
      VB: 0,
      WB: 0,
      XB: 0,
      YB: 0,
      ZB: 0.007116,
      aB: 0,
      bB: 0,
      cB: 0,
      dB: 0,
      eB: 0,
      fB: 0,
      gB: 0.003558,
      hB: 0,
      iB: 0.003558,
      jB: 0.014232,
      kB: 0.014232,
      lB: 0.024906,
      mB: 0,
      nB: 0.007116,
      oB: 0.007116,
      pB: 0,
      qB: 0,
      rB: 0.010674,
      sB: 0,
      tB: 0.007116,
      JC: 0,
      uB: 0,
      KC: 0.007116,
      vB: 0,
      wB: 0,
      xB: 0,
      yB: 0,
      zB: 0.021348,
      "0B": 0,
      "1B": 0,
      "2B": 0.007116,
      "3B": 0.010674,
      "4B": 0,
      "5B": 0,
      "6B": 0.007116,
      "7B": 0.007116,
      "8B": 0.003558,
      "9B": 0.003558,
      AC: 0.010674,
      BC: 0.010674,
      Q: 0.08895,
      I: 0.010674,
      R: 0.024906,
      S: 0.032022,
      T: 0,
      U: 0.010674,
      V: 0.01779,
      W: 0.067602,
      X: 0.010674,
      Y: 0.007116,
      Z: 0.007116,
      a: 0.03558,
      b: 0.010674,
      c: 0.010674,
      d: 0.032022,
      e: 0.014232,
      f: 0.007116,
      g: 0.014232,
      h: 0.039138,
      i: 0.010674,
      j: 0.007116,
      k: 0.014232,
      l: 0.010674,
      m: 0.099624,
      n: 0.042696,
      o: 0.010674,
      p: 0.01779,
      q: 0.024906,
      r: 0.039138,
      s: 1.04605,
      t: 0.021348,
      u: 0.028464,
      v: 0.039138,
      w: 0.145878,
      x: 0.07116,
      AB: 0.039138,
      BB: 0.056928,
      CB: 0.08895,
      DB: 0.074718,
      EB: 0.078276,
      FB: 0.138762,
      GB: 0.814782,
      HB: 0.295314,
      IB: 0.224154,
      JB: 0.156552,
      KB: 0.14232,
      LB: 0.384264,
      MB: 12.7946,
      D: 3.25201,
      MC: 0.014232,
      NC: 0.007116,
      OC: 0,
      PC: 0
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "J", "NB", "K", "E", "F", "G", "A", "B", "C", "L", "M", "H", "N", "O", "P", "OB", "y", "z", "0", "1", "2", "3", "4", "5", "PB", "QB", "RB", "SB", "TB", "UB", "VB", "WB", "XB", "YB", "ZB", "aB", "bB", "cB", "dB", "eB", "fB", "gB", "hB", "iB", "jB", "kB", "lB", "mB", "nB", "oB", "pB", "qB", "rB", "sB", "tB", "JC", "uB", "KC", "vB", "wB", "xB", "yB", "zB", "0B", "1B", "2B", "3B", "4B", "5B", "6B", "7B", "8B", "9B", "AC", "BC", "Q", "I", "R", "S", "T", "U", "V", "W", "X", "Y", "Z", "a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "6", "7", "8", "9", "AB", "BB", "CB", "DB", "EB", "FB", "GB", "HB", "IB", "JB", "KB", "LB", "MB", "D", "MC", "NC", "OC", "PC"],
    E: "Chrome",
    F: {
      "0": 1343692800,
      "1": 1348531200,
      "2": 1352246400,
      "3": 1357862400,
      "4": 1361404800,
      "5": 1364428800,
      "6": 1689724800,
      "7": 1692057600,
      "8": 1694476800,
      "9": 1696896000,
      J: 1264377600,
      NB: 1274745600,
      K: 1283385600,
      E: 1287619200,
      F: 1291248000,
      G: 1296777600,
      A: 1299542400,
      B: 1303862400,
      C: 1307404800,
      L: 1312243200,
      M: 1316131200,
      H: 1316131200,
      N: 1319500800,
      O: 1323734400,
      P: 1328659200,
      OB: 1332892800,
      y: 1337040000,
      z: 1340668800,
      PB: 1369094400,
      QB: 1374105600,
      RB: 1376956800,
      SB: 1384214400,
      TB: 1389657600,
      UB: 1392940800,
      VB: 1397001600,
      WB: 1400544000,
      XB: 1405468800,
      YB: 1409011200,
      ZB: 1412640000,
      aB: 1416268800,
      bB: 1421798400,
      cB: 1425513600,
      dB: 1429401600,
      eB: 1432080000,
      fB: 1437523200,
      gB: 1441152000,
      hB: 1444780800,
      iB: 1449014400,
      jB: 1453248000,
      kB: 1456963200,
      lB: 1460592000,
      mB: 1464134400,
      nB: 1469059200,
      oB: 1472601600,
      pB: 1476230400,
      qB: 1480550400,
      rB: 1485302400,
      sB: 1489017600,
      tB: 1492560000,
      JC: 1496707200,
      uB: 1500940800,
      KC: 1504569600,
      vB: 1508198400,
      wB: 1512518400,
      xB: 1516752000,
      yB: 1520294400,
      zB: 1523923200,
      "0B": 1527552000,
      "1B": 1532390400,
      "2B": 1536019200,
      "3B": 1539648000,
      "4B": 1543968000,
      "5B": 1548720000,
      "6B": 1552348800,
      "7B": 1555977600,
      "8B": 1559606400,
      "9B": 1564444800,
      AC: 1568073600,
      BC: 1571702400,
      Q: 1575936000,
      I: 1580860800,
      R: 1586304000,
      S: 1589846400,
      T: 1594684800,
      U: 1598313600,
      V: 1601942400,
      W: 1605571200,
      X: 1611014400,
      Y: 1614556800,
      Z: 1618272000,
      a: 1621987200,
      b: 1626739200,
      c: 1630368000,
      d: 1632268800,
      e: 1634601600,
      f: 1637020800,
      g: 1641340800,
      h: 1643673600,
      i: 1646092800,
      j: 1648512000,
      k: 1650931200,
      l: 1653350400,
      m: 1655769600,
      n: 1659398400,
      o: 1661817600,
      p: 1664236800,
      q: 1666656000,
      r: 1669680000,
      s: 1673308800,
      t: 1675728000,
      u: 1678147200,
      v: 1680566400,
      w: 1682985600,
      x: 1685404800,
      AB: 1698710400,
      BB: 1701993600,
      CB: 1705968000,
      DB: 1708387200,
      EB: 1710806400,
      FB: 1713225600,
      GB: 1715644800,
      HB: 1718064000,
      IB: 1721174400,
      JB: 1724112000,
      KB: 1726531200,
      LB: 1728950400,
      MB: 1731369600,
      D: 1736812800,
      MC: 1738627200,
      NC: null,
      OC: null,
      PC: null
    }
  },
  E: {
    A: {
      J: 0,
      NB: 0,
      K: 0,
      E: 0,
      F: 0,
      G: 0,
      A: 0,
      B: 0,
      C: 0,
      L: 0.003558,
      M: 0.01779,
      H: 0.003558,
      qC: 0,
      QC: 0,
      rC: 0,
      sC: 0,
      tC: 0,
      uC: 0,
      RC: 0,
      CC: 0.003558,
      DC: 0.007116,
      vC: 0.039138,
      wC: 0.05337,
      xC: 0.010674,
      SC: 0.007116,
      TC: 0.014232,
      EC: 0.01779,
      yC: 0.167226,
      FC: 0.024906,
      UC: 0.028464,
      VC: 0.021348,
      WC: 0.049812,
      XC: 0.01779,
      YC: 0.028464,
      zC: 0.224154,
      GC: 0.014232,
      ZC: 0.024906,
      aC: 0.024906,
      bC: 0.028464,
      cC: 0.064044,
      dC: 0.131646,
      "0C": 0.39138,
      HC: 0.067602,
      eC: 0.939312,
      fC: 0.416286,
      gC: 0.028464,
      hC: 0,
      "1C": 0
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "qC", "QC", "J", "NB", "rC", "K", "sC", "E", "tC", "F", "G", "uC", "A", "RC", "B", "CC", "C", "DC", "L", "vC", "M", "wC", "H", "xC", "SC", "TC", "EC", "yC", "FC", "UC", "VC", "WC", "XC", "YC", "zC", "GC", "ZC", "aC", "bC", "cC", "dC", "0C", "HC", "eC", "fC", "gC", "hC", "1C", ""],
    E: "Safari",
    F: {
      qC: 1205798400,
      QC: 1226534400,
      J: 1244419200,
      NB: 1275868800,
      rC: 1311120000,
      K: 1343174400,
      sC: 1382400000,
      E: 1382400000,
      tC: 1410998400,
      F: 1413417600,
      G: 1443657600,
      uC: 1458518400,
      A: 1474329600,
      RC: 1490572800,
      B: 1505779200,
      CC: 1522281600,
      C: 1537142400,
      DC: 1553472000,
      L: 1568851200,
      vC: 1585008000,
      M: 1600214400,
      wC: 1619395200,
      H: 1632096000,
      xC: 1635292800,
      SC: 1639353600,
      TC: 1647216000,
      EC: 1652745600,
      yC: 1658275200,
      FC: 1662940800,
      UC: 1666569600,
      VC: 1670889600,
      WC: 1674432000,
      XC: 1679875200,
      YC: 1684368000,
      zC: 1690156800,
      GC: 1695686400,
      ZC: 1698192000,
      aC: 1702252800,
      bC: 1705881600,
      cC: 1709596800,
      dC: 1715558400,
      "0C": 1722211200,
      HC: 1726444800,
      eC: 1730073600,
      fC: 1733875200,
      gC: 1737936000,
      hC: null,
      "1C": null
    }
  },
  F: {
    A: {
      "0": 0,
      "1": 0,
      "2": 0,
      "3": 0,
      "4": 0,
      "5": 0,
      G: 0,
      B: 0,
      C: 0,
      H: 0,
      N: 0,
      O: 0,
      P: 0,
      OB: 0,
      y: 0,
      z: 0,
      PB: 0,
      QB: 0,
      RB: 0,
      SB: 0,
      TB: 0,
      UB: 0,
      VB: 0,
      WB: 0,
      XB: 0,
      YB: 0,
      ZB: 0,
      aB: 0,
      bB: 0.003558,
      cB: 0,
      dB: 0,
      eB: 0,
      fB: 0,
      gB: 0,
      hB: 0.014232,
      iB: 0,
      jB: 0,
      kB: 0,
      lB: 0,
      mB: 0,
      nB: 0,
      oB: 0,
      pB: 0,
      qB: 0,
      rB: 0,
      sB: 0,
      tB: 0,
      uB: 0,
      vB: 0,
      wB: 0,
      xB: 0,
      yB: 0,
      zB: 0,
      "0B": 0,
      "1B": 0,
      "2B": 0,
      "3B": 0,
      "4B": 0,
      "5B": 0,
      "6B": 0,
      "7B": 0,
      "8B": 0,
      "9B": 0,
      AC: 0,
      BC: 0,
      Q: 0,
      I: 0,
      R: 0,
      LC: 0,
      S: 0,
      T: 0,
      U: 0,
      V: 0.032022,
      W: 0.010674,
      X: 0,
      Y: 0,
      Z: 0,
      a: 0,
      b: 0,
      c: 0,
      d: 0,
      e: 0.032022,
      f: 0,
      g: 0,
      h: 0,
      i: 0,
      j: 0,
      k: 0,
      l: 0.010674,
      m: 0,
      n: 0,
      o: 0,
      p: 0,
      q: 0,
      r: 0,
      s: 0,
      t: 0,
      u: 0,
      v: 0,
      w: 0,
      x: 0.313104,
      "2C": 0,
      "3C": 0,
      "4C": 0,
      "5C": 0,
      CC: 0,
      iC: 0,
      "6C": 0,
      DC: 0
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "G", "2C", "3C", "4C", "5C", "B", "CC", "iC", "6C", "C", "DC", "H", "N", "O", "P", "OB", "y", "z", "0", "1", "2", "3", "4", "5", "PB", "QB", "RB", "SB", "TB", "UB", "VB", "WB", "XB", "YB", "ZB", "aB", "bB", "cB", "dB", "eB", "fB", "gB", "hB", "iB", "jB", "kB", "lB", "mB", "nB", "oB", "pB", "qB", "rB", "sB", "tB", "uB", "vB", "wB", "xB", "yB", "zB", "0B", "1B", "2B", "3B", "4B", "5B", "6B", "7B", "8B", "9B", "AC", "BC", "Q", "I", "R", "LC", "S", "T", "U", "V", "W", "X", "Y", "Z", "a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "", "", ""],
    E: "Opera",
    F: {
      "0": 1401753600,
      "1": 1405987200,
      "2": 1409616000,
      "3": 1413331200,
      "4": 1417132800,
      "5": 1422316800,
      G: 1150761600,
      "2C": 1223424000,
      "3C": 1251763200,
      "4C": 1267488000,
      "5C": 1277942400,
      B: 1292457600,
      CC: 1302566400,
      iC: 1309219200,
      "6C": 1323129600,
      C: 1323129600,
      DC: 1352073600,
      H: 1372723200,
      N: 1377561600,
      O: 1381104000,
      P: 1386288000,
      OB: 1390867200,
      y: 1393891200,
      z: 1399334400,
      PB: 1425945600,
      QB: 1430179200,
      RB: 1433808000,
      SB: 1438646400,
      TB: 1442448000,
      UB: 1445904000,
      VB: 1449100800,
      WB: 1454371200,
      XB: 1457308800,
      YB: 1462320000,
      ZB: 1465344000,
      aB: 1470096000,
      bB: 1474329600,
      cB: 1477267200,
      dB: 1481587200,
      eB: 1486425600,
      fB: 1490054400,
      gB: 1494374400,
      hB: 1498003200,
      iB: 1502236800,
      jB: 1506470400,
      kB: 1510099200,
      lB: 1515024000,
      mB: 1517961600,
      nB: 1521676800,
      oB: 1525910400,
      pB: 1530144000,
      qB: 1534982400,
      rB: 1537833600,
      sB: 1543363200,
      tB: 1548201600,
      uB: 1554768000,
      vB: 1561593600,
      wB: 1566259200,
      xB: 1570406400,
      yB: 1573689600,
      zB: 1578441600,
      "0B": 1583971200,
      "1B": 1587513600,
      "2B": 1592956800,
      "3B": 1595894400,
      "4B": 1600128000,
      "5B": 1603238400,
      "6B": 1613520000,
      "7B": 1612224000,
      "8B": 1616544000,
      "9B": 1619568000,
      AC: 1623715200,
      BC: 1627948800,
      Q: 1631577600,
      I: 1633392000,
      R: 1635984000,
      LC: 1638403200,
      S: 1642550400,
      T: 1644969600,
      U: 1647993600,
      V: 1650412800,
      W: 1652745600,
      X: 1654646400,
      Y: 1657152000,
      Z: 1660780800,
      a: 1663113600,
      b: 1668816000,
      c: 1668643200,
      d: 1671062400,
      e: 1675209600,
      f: 1677024000,
      g: 1679529600,
      h: 1681948800,
      i: 1684195200,
      j: 1687219200,
      k: 1690329600,
      l: 1692748800,
      m: 1696204800,
      n: 1699920000,
      o: 1699920000,
      p: 1702944000,
      q: 1707264000,
      r: 1710115200,
      s: 1711497600,
      t: 1716336000,
      u: 1719273600,
      v: 1721088000,
      w: 1724284800,
      x: 1727222400
    },
    D: {
      G: "o",
      B: "o",
      C: "o",
      "2C": "o",
      "3C": "o",
      "4C": "o",
      "5C": "o",
      CC: "o",
      iC: "o",
      "6C": "o",
      DC: "o"
    }
  },
  G: {
    A: {
      F: 0,
      QC: 0,
      "7C": 0,
      jC: 0.00293498,
      "8C": 0,
      "9C": 0.00733744,
      AD: 0.00880493,
      BD: 0,
      CD: 0.00293498,
      DD: 0.0220123,
      ED: 0.00440246,
      FD: 0.0322847,
      GD: 0.0924517,
      HD: 0.0102724,
      ID: 0.00586995,
      JD: 0.155554,
      KD: 0.00293498,
      LD: 0.0176099,
      MD: 0.00586995,
      ND: 0.0220123,
      OD: 0.143814,
      PD: 0.0689719,
      QD: 0.0352197,
      SC: 0.0366872,
      TC: 0.0425571,
      EC: 0.0498946,
      RD: 0.585528,
      FC: 0.0880493,
      UC: 0.192241,
      VC: 0.0983217,
      WC: 0.171696,
      XC: 0.0366872,
      YC: 0.0704394,
      SD: 0.713199,
      GC: 0.0454921,
      ZC: 0.0807118,
      aC: 0.063102,
      bC: 0.0895167,
      cC: 0.192241,
      dC: 0.463726,
      TD: 1.59369,
      HC: 0.490141,
      eC: 6.52592,
      fC: 2.20123,
      gC: 0.190773,
      hC: 0
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "QC", "7C", "jC", "8C", "9C", "AD", "F", "BD", "CD", "DD", "ED", "FD", "GD", "HD", "ID", "JD", "KD", "LD", "MD", "ND", "OD", "PD", "QD", "SC", "TC", "EC", "RD", "FC", "UC", "VC", "WC", "XC", "YC", "SD", "GC", "ZC", "aC", "bC", "cC", "dC", "TD", "HC", "eC", "fC", "gC", "hC", "", ""],
    E: "Safari on iOS",
    F: {
      QC: 1270252800,
      "7C": 1283904000,
      jC: 1299628800,
      "8C": 1331078400,
      "9C": 1359331200,
      AD: 1394409600,
      F: 1410912000,
      BD: 1413763200,
      CD: 1442361600,
      DD: 1458518400,
      ED: 1473724800,
      FD: 1490572800,
      GD: 1505779200,
      HD: 1522281600,
      ID: 1537142400,
      JD: 1553472000,
      KD: 1568851200,
      LD: 1572220800,
      MD: 1580169600,
      ND: 1585008000,
      OD: 1600214400,
      PD: 1619395200,
      QD: 1632096000,
      SC: 1639353600,
      TC: 1647216000,
      EC: 1652659200,
      RD: 1658275200,
      FC: 1662940800,
      UC: 1666569600,
      VC: 1670889600,
      WC: 1674432000,
      XC: 1679875200,
      YC: 1684368000,
      SD: 1690156800,
      GC: 1694995200,
      ZC: 1698192000,
      aC: 1702252800,
      bC: 1705881600,
      cC: 1709596800,
      dC: 1715558400,
      TD: 1722211200,
      HC: 1726444800,
      eC: 1730073600,
      fC: 1733875200,
      gC: 1737936000,
      hC: null
    }
  },
  H: {
    A: {
      UD: 0.04
    },
    B: "o",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "UD", "", "", ""],
    E: "Opera Mini",
    F: {
      UD: 1426464000
    }
  },
  I: {
    A: {
      IC: 0,
      J: 0,
      D: 0.308567,
      VD: 0,
      WD: 0,
      XD: 0,
      YD: 0.0000309216,
      jC: 0.0000618432,
      ZD: 0,
      aD: 0.000371059
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "VD", "WD", "XD", "IC", "J", "YD", "jC", "ZD", "aD", "D", "", "", ""],
    E: "Android Browser",
    F: {
      VD: 1256515200,
      WD: 1274313600,
      XD: 1291593600,
      IC: 1298332800,
      J: 1318896000,
      YD: 1341792000,
      jC: 1374624000,
      ZD: 1386547200,
      aD: 1401667200,
      D: 1737676800
    }
  },
  J: {
    A: {
      E: 0,
      A: 0
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "E", "A", "", "", ""],
    E: "Blackberry Browser",
    F: {
      E: 1325376000,
      A: 1359504000
    }
  },
  K: {
    A: {
      A: 0,
      B: 0,
      C: 0,
      I: 1.03581,
      CC: 0,
      iC: 0,
      DC: 0
    },
    B: "o",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "A", "B", "CC", "iC", "C", "DC", "I", "", "", ""],
    E: "Opera Mobile",
    F: {
      A: 1287100800,
      B: 1300752000,
      CC: 1314835200,
      iC: 1318291200,
      C: 1330300800,
      DC: 1349740800,
      I: 1709769600
    },
    D: {
      I: "webkit"
    }
  },
  L: {
    A: {
      D: 46.1904
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "D", "", "", ""],
    E: "Chrome for Android",
    F: {
      D: 1737676800
    }
  },
  M: {
    A: {
      D: 0.334984
    },
    B: "moz",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "D", "", "", ""],
    E: "Firefox for Android",
    F: {
      D: 1730160000
    }
  },
  N: {
    A: {
      A: 0,
      B: 0
    },
    B: "ms",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "A", "B", "", "", ""],
    E: "IE Mobile",
    F: {
      A: 1340150400,
      B: 1353456000
    }
  },
  O: {
    A: {
      EC: 0.83746
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "EC", "", "", ""],
    E: "UC Browser for Android",
    F: {
      EC: 1710115200
    },
    D: {
      EC: "webkit"
    }
  },
  P: {
    A: {
      "0": 0.0330772,
      "1": 0.0330772,
      "2": 0.0441029,
      "3": 0.0441029,
      "4": 0.110257,
      "5": 1.90745,
      J: 0.0661544,
      y: 0,
      z: 0.0220515,
      bD: 0.0110257,
      cD: 0,
      dD: 0.0110257,
      eD: 0,
      fD: 0,
      RC: 0,
      gD: 0,
      hD: 0,
      iD: 0,
      jD: 0,
      kD: 0,
      FC: 0,
      GC: 0.0110257,
      HC: 0,
      lD: 0
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "J", "bD", "cD", "dD", "eD", "fD", "RC", "gD", "hD", "iD", "jD", "kD", "FC", "GC", "HC", "lD", "y", "z", "0", "1", "2", "3", "4", "5", "", "", ""],
    E: "Samsung Internet",
    F: {
      "0": 1689292800,
      "1": 1697587200,
      "2": 1711497600,
      "3": 1715126400,
      "4": 1717718400,
      "5": 1725667200,
      J: 1461024000,
      bD: 1481846400,
      cD: 1509408000,
      dD: 1528329600,
      eD: 1546128000,
      fD: 1554163200,
      RC: 1567900800,
      gD: 1582588800,
      hD: 1593475200,
      iD: 1605657600,
      jD: 1618531200,
      kD: 1629072000,
      FC: 1640736000,
      GC: 1651708800,
      HC: 1659657600,
      lD: 1667260800,
      y: 1677369600,
      z: 1684454400
    }
  },
  Q: {
    A: {
      mD: 0.199702
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "mD", "", "", ""],
    E: "QQ Browser",
    F: {
      mD: 1710288000
    }
  },
  R: {
    A: {
      nD: 0
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "nD", "", "", ""],
    E: "Baidu Browser",
    F: {
      nD: 1710201600
    }
  },
  S: {
    A: {
      oD: 0.019326,
      pD: 0
    },
    B: "moz",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "oD", "pD", "", "", ""],
    E: "KaiOS Browser",
    F: {
      oD: 1527811200,
      pD: 1631664000
    }
  }
};
export default exports;